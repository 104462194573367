* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_outside {
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: 5px 10px 18px #888888;
}

.handlerContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.handlerBody {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
}
.handlerBody > * {
  padding: 10px 8px;
  margin: 10px;
}
.handlerBodyDesc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
}
.handlerBodyDesc > * {
  padding: 10px 8px 1px;
  margin: 8px;
}
.bodyDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px 30px;
  flex-wrap: wrap;
}
.bodyDetails > * {
  padding: 20px;
}
.displayDetails {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.displayDetails h1 {
  font-size: 20px;
}
.paymentDetails {
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.paymentStatus {
  padding: 20px;
}
.paymentStatus p {
  margin-top: 10px;
}
.displayCircularLoader {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.circularLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.paymentContent {
  display: flex;
  flex-direction: column;
}
.copyButton > * {
  width: 100%;
}
